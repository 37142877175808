<template>
	<el-menu :default-active="activeItem" class="el-menu-vertical-demo" background-color="#2f374a" text-color="#fff"
	 router :unique-opened="true">
		<!-- @select="clickMenuItem"  去掉点击事件，解决clickMenuItem方法执行两次的bug-->
		<template v-for="(item,index) in menus">
			<el-submenu v-if="item.hasChilder" :index="item.index" :key="index">
				<template slot="title">
					<!-- <i class="el-icon-document"></i> -->
					<!-- <img :src="item.url" alt=""> -->
					<!-- <i class="iconfont" :class="item.icon"></i> -->
					<span>{{item.name}}</span>
				</template>
				<template v-for="(v,i) in item.children">
					<el-menu-item :index="v.index" :key="i">{{v.name}}</el-menu-item>
				</template>
			</el-submenu>
			<template v-else>
				<el-menu-item :key="index" :index="item.index">
					<template slot="title">
						<!-- <i class="el-icon-location"></i> -->
						<!-- <img :src="item.url" alt=""> -->
						<!-- <i class="iconfont" :class="item.icon"></i> -->
						<span>{{item.name}}</span>
					</template>
				</el-menu-item>
			</template>
		</template>
	</el-menu>
</template>

<script>
import {
  mapState,
  mapActions,
  mapMutations
} from 'vuex'

export default {
  data () {
    return {
      currFatherIndex: '',
      menus: []
      // menu:[]
    }
  },
  // props:{
  //   Menulist:{
  //     type:Array
  //   }
  // },

  created () {
    // this.GetUserMenu()
    // console.log(this.menu)
    this.projectPermissions()
    this.setMenus()
    // console.log(this.menu)
    // this.authority()
    // this.getMenu(this.Menulist);
  },
  watch: {
    $route (to, from) {
      console.log(to.name)
      if (to.name == 'commoncodenumber' || to.name == 'commondataspecification' || to.name == 'companycodenumber' || to.name == 'companydataspecification') {
        return
      }
      this.setMenus()
    }
  },
  methods: {
    ...mapActions('menu', {
      getMenu: 'getMenu',
      clickMenuItem: 'clickMenuItem'
    }),
    ...mapMutations('menu', {
      initMenu: 'initMenu'
    }),
    setMenus () {
      let routeName = this.$route.name
      let routePath = this.$route.path
      console.log(this.$route)
      if (routePath == '/home/companydataspecification' || routePath == '/home/companycodenumber' || routePath == '/home/commondataspecification' || routePath == '/home/commoncodenumber') {
        this.menus = this.menu
      } else {
        if (routeName == 'space' || routeName == 'commoncodenumber' || routeName == 'commondataspecification') {
          this.menus = [this.menu[0]]
          let vuexMenu = this.menu.slice(0, 2)
          this.initMenu(vuexMenu)
        } else if (routeName == 'projectType' || routeName == 'project' || routeName == 'companycodenumber' || routeName == 'companydataspecification') {
          this.menus = this.menu.slice(0, 2)
          this.initMenu(this.menus)
        } else {
          this.menus = this.menu
        }
      }
    },
    async projectPermissions () {
      // await this.authority()
      await this.GetUserMenu()
    },

    // 权限管理

    // 不在此处获取菜单，只因为在此处获取菜单刷新界面时，不能"以当前路由做为tab加入tabs"
    // 获取当前用户有权限的菜单
    GetUserMenu () {
      // this.getMenu([]);
      // console.log(this.menu)
      // console.log()
      // this.$axios
      // .get(
      //   `/api/User/GetUserMenu?projectId=${localStorage.eleProjectId}`
      // )
      // .then(res => {
      //   //将获取的菜单存入状态管理中
      //  res.System_Menu.unshift({ name: '首页', hasChilder: false, index: 'index', children: [], includeCom: 'Index' })
      //  this.getMenu(res.System_Menu);
      // // 刷新时以当前路由做为tab加入tabs（必须放在菜单加载完成之后执行，否则menu为空,报错：Error in created hook: "TypeError: Cannot read property 'name' of undefined"）
      // console.log(this.$route.name)
      // this.clickMenuItem(this.$route.name)
      // this.switchTab(this.$route.name)
      // });
    }

    // 改变路由
    // clickMenuItem(e){
    //   this.$router.push({ path: e })
    // }
  },
  computed: {
    ...mapState('menu', {
      menu: 'menu',
      activeItem: 'activeItem'
    })
  }
}
</script>

<style scoped>
	.el-menu>ul,
	.el-menu {
		height: 100%;
	}

	.el-aside {
		height: 100%;
	}
</style>
