<template>
	<!-- 参考element-ui中Tabs组件 -->
	<el-tabs v-if="isTabs" :value="activeItem" @tab-remove="tabRemove" class='content-body' @tab-click="tabClick" >
		<el-tab-pane v-for="item in tabs" :key="item.index" :name="item.index" :closable="item.closable">
			<span class="tabs-container" slot="label" >
				<i class="iconfont" :class="item.icon"></i>
				<span>{{item.label}}</span>
			</span>
		</el-tab-pane>
	</el-tabs>
</template>

<script>
import {
  mapActions,
  mapState,
  mapMutations
} from 'vuex'
export default {
  data () {
    return {
      isTabs: false
    }
  },
  computed: {
    ...mapState('menu', {
      tabs: 'tabs',
      activeItem: 'activeItem'
    })
  },
  created () {
    console.log(this.tabs)
  },
  methods: {
    ...mapActions('menu', {
      closeTab: 'closeTab'
    }),
    ...mapMutations('menu', {
      switchTab: 'switchTab'
    }),
    tabClick (e) {
      this.switchTab(e.name)
      this.$router.push({
        path: e.name
      })
    },
    tabRemove (e) {
      let t = this
      // 异步阻塞一下，否则activeItem还是当前关闭tab的值
      setTimeout(function () {
        t.$router.push({
          path: t.activeItem
        })
      }, 1)
      this.closeTab(e)
    }
  }
}
</script>
<style scoped>
	.content-body {
		height: 40px !important;
	}

	.tabs-container {
		position: relative;
		display: inline-block;
		padding: 0 30px 0 45px;
		line-height: 40px;
	}
	.tabs-container i{
		position: absolute;
		left: 20px;
		top: 50%;
		transform: translateY(-50%);
	}
	/deep/.el-tabs__nav-scroll{
		background-color: #f8f8f9;
	}
	/* el-tabs */
	/deep/ .el-tabs__item.is-active{
		 background-color: #e5e5e5;
	    color:rgb(71, 80, 101) !important;
		border: none;
		font-weight: bold;
	  }

	/deep/.el-tabs__active-bar{
	    background-color:rgb(71, 80, 101) !important;
		top: 0 !important;
		left: 1px !important;
		height: 5px !important;

	    }
	/deep/.el-tabs__item{
		color: #747474 !important;
		color: #000000;
		 background-color: #f8f8f9;
		 padding: 0 !important;
		 border-top: 1px solid #cbcbcb;
		 border-right: 1px solid #CBCBCB;
	}
	/deep/.el-icon-close{
		position: absolute;
		right: 8px;
		top: 50%;
		transform: translateY(-50%);
	}
</style>
