<template>
	<el-container>
		<el-header>
			<top-bar />
		</el-header>
		<el-container>
			<el-aside width="131px" v-if="isMneu">
				<left-nav />
			</el-aside>
			<el-main>
				<right-tabs />
				<div class="content-wrap">
					<!--keep-alive 缓存组件 -->
					<keep-alive :include="includeCom">
						<router-view :key="$route.fullPath" />
					</keep-alive>
				</div>
			</el-main>
		</el-container>
	</el-container>
</template>

<script>
import LeftNav from '@/components/layout/LeftNav.vue'
import RightTabs from '@/components/layout/RightTabs.vue'
import TopBar from '@/components/layout/TopBar.vue'
import {
  mapState,
  mapActions
} from 'vuex'

export default {
  name: 'Home',
  data () {
    return {
      // routerName: '',
      isMneu: false
    }
  },
  components: {
    LeftNav,
    RightTabs,
    TopBar
  },
  computed: {
    ...mapState('menu', {
      includeCom: 'includeCom'
    })
  },
  mounted () {
    // console.log(this.$route)
    // this.routerName = this.$route.name
    this.isLeftMenu()
  },

  methods: {
    ...mapActions('menu', {
      clickMenuItem: 'clickMenuItem'
    }),
    isLeftMenu () {
      let routeName = this.$route.name
      // console.log(routerName)
      // routeName == 'space' || routeName == 'projectType' || routeName == 'project' ||
      if (routeName == 'ownProjectType' || routeName == 'adminSpace' || routeName == 'userinfo' || routeName == 'companyproject' || routeName == 'model') {
        this.isMneu = false
      } else {
        this.isMneu = true
      }
    }
  },
  // watch监听主要是为了解决浏览器前进后退时，tab栏对应选中当前路由
  watch: {
    $route (to, from) {
      // console.log(to)
      this.isLeftMenu()
      if (to.name == 'space' || to.name == 'projectType' || to.name == 'project' | to.name == 'ownProjectType' || to.name == 'adminSpace' || to.name == 'userinfo' || to.name == 'companyproject' || to.name == 'model') {

      } else {
        this.clickMenuItem(to.name)
      }
    }
  }
}
</script>

<style>
	.el-header {
		/* background-color: #b3c0d1; */
		box-shadow: 0 2px 4px rgba(0, 0, 0, .08);
		color: #333;
		text-align: center;
		line-height: 60px;
		padding: 0px;
	}

	.el-aside {

		background-color: #d3dce6;
		color: #333;
		text-align: left;
		line-height: 200px;
		height: calc(100% + 62px);
	}

	.el-main {
		background-color: #e5e5e5;
		color: #333;
		text-align: center;
		height: calc(100% + 62px);  /**多加2px 是因为去掉了body默认的边框 */
		padding: 0 !important;
	}

	.el-container {
		/* height: calc(100% - 60px); */
		height: 847px;
	}

	.content-wrap {
		/* border: 1px solid #dcdff7; */
		/* background-color: #000000; */
		border-top: none;
		/* height: 100%; */
		height: calc(100% - 60px);
	}
</style>
